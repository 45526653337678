import React from 'react';
import { Section } from '../@components';
import { getProductConfig } from '../@lib';

const Summary = (props) => {
  const { item, model } = props;
  let product = getProductConfig(item.productCode);
  if (product.withFilteredSections) {
    product = product.withFilteredSections(item.properties);
  }
  return(
    <>
    {
      product.sections?.map((section,index) => {
        return(
          <Section
              key={`section${section.title}${index}`}
            title={section.title} 
            model={model.slice(section.startIndexInclusive, section.endIndexInclusive + 1)}
            item={item}
            sectionData={section}
          />
        )
      })
    }
    <div className='notice'>
      This is only a summary of the insurance policy, and should not be considered as the official insurance policy document.
    </div>
    </>
  )
};

export default Summary;

