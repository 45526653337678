import numeral from "numeral";
import getProductConfig from './porductConfig';

const removeHashSlash = (str) => {
  if(!str)
      return '';
  return str.replace(/#\//g, '');
};
const resolveObjKey = (obj, path) => {
  if (!path) return null;

  return path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj);
};
const formatCurrency = (value, config) => {
  if(!value)
    return '';

  const theNumeral = numeral(value);
  return `${config.symbol} ${theNumeral.format(config.format)}`;
}
const isWalletOrCash = (productName) => ['Wallet Insurance', 'Cash for Hospitalisation'].includes(productName);
const arrayHasValue = (array) => array && array.length > 0;
export {
  removeHashSlash,
  resolveObjKey,
  formatCurrency,
  isWalletOrCash,
  arrayHasValue,
  getProductConfig,
};