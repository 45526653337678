import React from 'react';
import { Summary, Canceled, Expired } from '../@containers';

const Body = (props) => {
  const { policy } = props;
  const fields = policy.fields ? policy.fields : [];
  const item = policy.list ? policy.list[0] : {};
  const isCancelled = item.status === 'Cancelled';
  const isExpired = item.status === 'Expired';
  return(
    <div className='xc-body'>
      {
        isCancelled ? <Canceled item={item} model={fields[0]} />
      : isExpired ? <Expired item={item} model={fields[0]} />
      : <Summary item={item} model={fields} />
      }
    </div>
  )
};

export default Body;

