import React from 'react';
import { resolveObjKey } from '../@lib';

const PolicyExpired = (props) => {
  const { model, item } = props;
  return(
    <>
      <div className='row space-btn'>
        <p className='title txt-light'>{model.displayName}:</p>
        <p>{resolveObjKey(item, model.id)}</p>
      </div>
      <p className='title txt-light'>This policy has expired.</p>
    </>
  )
};

export default PolicyExpired;

