const noAddOnCountries = ['Andorra', 'Argentina', 'Aruba', 'Bahrain', 'Brazil', 'Costa Rica', 'Czechia', 'Czech Republic', 'France', 'French Polynesia', 'Guam', 'Israel', 'Kuwait', 'Maldives', 'Montenegro', 'Occupied Palestinian territory', 'Panama', 'Peru', 'Puerto Rico', 'Saint Martin', 'Spain', 'Serbia and Montenegro'];

const product = {
  color: '#c1241b', 
  currencySymbol: 'PKR',
  currencyFormat: '0,0',
  dateFormat: 'DD/MM/YYYY',
  favicon: require(`../@theme/images/alfalah/favicon.ico`), /**require path cannot be dynamic */
  logo:  require(`../@theme/images/alfalah/logo.png`),  /**require path cannot be dynamic */
  title: 'Alfalah Insurance', 
  sections: [
    {title: 'POLICY DETAILS', startIndexInclusive: 0, endIndexInclusive: 12},
    {title: 'COVID-19 ADD ON DETAILS', startIndexInclusive: 13, endIndexInclusive: 15},
    {title: 'INSURED DETAILS', startIndexInclusive: 16, endIndexInclusive: 20},
    {title: 'BENEFICIARY DETAILS', startIndexInclusive: 21, endIndexInclusive: 21},
    {title: 'MEDICAL BENEFIT', rows: [
      {
        title: `Accidental & Sickness Medical Expenses (Hospitalization & OPD) (Deductible 5% of EEL* or USD 100 on EEL* whichever is higher)`,
        getValue: (policyObj) => {
          const { properties: { Plan } } = policyObj;
          switch (Plan) {
            case 'Basic':
              return 'US$ 25,000';
            case 'Executive':
              return 'US$ 50,000 (Hospitalization Only)';
            case 'Titanium':
              return 'US$ 50,000';
            case 'Platinum':
              return 'US$ 100,000';
            default:
              return 'Plan not found';
          }
        }
      },
    ]},
    {title: '', rows: [
      {
        title: `<i><small>*Each and Every Loss</small></i>`,
        getValue: () => "",
      },
    ]},
  ],
  calculations: [
    {
      id: 'properties.End Date', 
      calculate: (value, format, moment) => moment(value, format).add('years', 1).format(format)
    },
  ],
  withFilteredSections(properties){
    const { 'Trip Type': tripType, destinationCountry } = properties;
    if (tripType === 'Annual' || noAddOnCountries.includes(destinationCountry)) {
      return {
        ...this,
        sections: this.sections.filter(section => section.title !== 'COVID-19 ADD ON DETAILS')
      };
    }
  return this;
  }
};

export default product;
