import React from 'react';
import { resolveObjKey, formatCurrency, getProductConfig } from '../@lib';

const RenderDate = ({item, model}) => {
  let value = resolveObjKey(item, model.id);

  // if( arrayHasValue(product.calculations) && isWalletOrCash(item.productName) ) {
  //   const calculation = product.calculations.find(field => field.id === model.id);
  //   value = calculation ? calculation.calculate(value, product.dateFormat, moment) : value;
  // }
  
  return(
    <div className={`row ${model.cssClass}`}>
      <p className='title txt-light'>{model.displayName}:</p>
      <p>{value}</p>
    </div>
  );
}

const RenderCurrency = ({item, model}) => {
  const product = getProductConfig(item.productCode);
  const value = resolveObjKey(item, model.id);
  const currencyConfig = {format: product.currencyFormat, symbol: product.currencySymbol}
  return(
    <div className={`row ${model.cssClass}`}>
      <p className='title txt-light'>{model.displayName}:</p>
      <p>{formatCurrency(value, currencyConfig)}</p>
    </div>
  );
}

const getValueBasedOnConfigType=(type , item , value)=>{
    const product = getProductConfig(item.productCode);
    const currencyConfig = {format: product.currencyFormat, symbol: product.currencySymbol}
    switch (type){
        case 'currency':
            return formatCurrency(value ,currencyConfig)
        case 'percent':
            return `${value}%`
        default:
            return value
    }
}

const RenderString = ({config,item, model}) => {
    let value =resolveObjKey(item, model.id);
    if(config && config.customRow){
        const targetField = config.customRow.find((el)=>el.id === model.id);
        if(targetField){
            value = getValueBasedOnConfigType(targetField.type , item , value)
        }
    }
    return (
        <div className={`row ${model.cssClass}`}>
            <p className='title txt-light'>{model.displayName}:</p>
            <p>{value}</p>
        </div>
    )
};

const RenderArray = ({item, model,customStyle}) => (
  item ? item.map((i) => { // for every object in the array
    return(
      <div style={customStyle}>

          {
              model.map((m) => { // for every model item
                  return(
                      <RenderSection model={m} item={i} parentItem={item}/>
                  )
              })
          }
      </div>
    );
  }) : <></>
);

const RenderSection = ({config,model, parentItem, item,customStyle}) => (
  <>
  {model.type === 'array' ? <RenderArray item={resolveObjKey(parentItem, model.id)} customStyle={customStyle} model={model.arrayFields} />
  : model.type === 'currency' ? <RenderCurrency item={item} model={model} />
  : model.type === 'date' ? <RenderDate item={item} model={model} />
  : <RenderString config={config} item={item} model={model} />
  }
  </>
);

const Section = (props) => {
  const { model, item, title, sectionData } = props;
  let product = getProductConfig(item.productCode);
  if(
      (product.title==="Motor Insurance" && sectionData?.title==="ADDITIONAL DRIVER DETAILS" && item?.properties["additional drivers"]===undefined)
      ||
      (product.title==="Personal Accident" && sectionData?.title==="SPOUSE DETAILS" && item?.properties["spouse"]===undefined)
  ){
      return null;
  }

  return(
    <div className='section'>
    <h5 className='txt-bold space-btn'>{title}</h5>
    {
      model.map((m,index) => {
        return(
            <div key={`SubSection${index}`}>
                <RenderSection config={sectionData.config} customStyle={sectionData.customStyle} model={m} item={item} parentItem={item}/>
            </div>
        );
      })
    }
      {!model.length && (
          sectionData && sectionData.rows && sectionData.rows.map((row) => (
          <div className='row static-section'>
            <span className='title txt-light p-2 width-50 ' dangerouslySetInnerHTML={{__html: row.title}}/>
            <span className='width-50 value' dangerouslySetInnerHTML={{__html: row.getValue(item)}}/>
          </div>
        ))
      )}
  </div>
  )
};

export default Section;
