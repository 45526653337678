import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import Main from "./main";
import PolicyIdForm from './PolicyIdForm';


const Navigation = () => (
  <div>
    <Switch>
      <Route exact={true} path='/' component={PolicyIdForm}/>
      <Route exact={true} path='/:token' component={Main}/>
    </Switch>
  </div>
);

export default withRouter(Navigation);