import React, {useState} from "react";
import {connect} from 'react-redux';

import Header from "../header";
import {getPolicyByRefCodeAndPassport} from '../../@redux/action';

const PolicyForm = (props) => {
  const [productCode, changeProductCode] = useState('2062582');
  const [refCode, changeRefCode] = useState('');
  const [passport, changePassport] = useState('');
  const [error, changeError] = useState('');
  const submit = async e => {
    e.preventDefault();
    const response = await props.getPolicy({refCode, passport});
    if (!response.error) {
      const {policyAccessQRUrl} = response;
      return props.history.push(`/${policyAccessQRUrl}`)
    }
    showErrors(response);
  };
  const showErrors = ({message}) => {
    changeError(message);
    setTimeout(() => changeError(""), 1000);
  }
  return (
    <div className="policy-form-container">
      <Header productCode={productCode}/>
      <h3>Check Your Insurance Policy Status</h3>
      <form onSubmit={submit}>
        <div>
          <label>Policy No.</label>
          <input
            placeholder='Enter your policy no.'
            value={refCode}
            onChange={({target: {value}}) => changeRefCode(value)}
          />
        </div>
        {error.length > 0 && (
          <div className="" style={{color: "red"}}>
            <p>{error}</p>
          </div>
        )}
        <div>
          <button>Check Policy Status</button>
        </div>
      </form>
    </div>
  );
};
const mapDispatchToProps = () => ({
  getPolicy: getPolicyByRefCodeAndPassport,
});
export default connect(() => {
}, mapDispatchToProps)(PolicyForm);