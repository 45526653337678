import { endpoints } from '../@config';

const handleRequest = async (url, options) => {
  try {
    const response = await fetch(url, options);
    return response;
  } catch (error) {
    throw new Error(error.message)
  }
};
const handleResponse = async (response) => {
  const status = response.status;
  const parsedResponse = await response.json();
  return {status: status, data: parsedResponse};
};
const getPolicyByQrCode = async (token = '') => {
  const api = 'policies/policy_qr';
  const url = `${endpoints.policy}/${api}/${token}`;
  const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
  };
  const response = await handleRequest(url, options);
  const parsedResponse = await handleResponse(response);
  return parsedResponse;
};

const getPolicyByRefCodeAndPassport = async ({refCode='', passport=''}) => {
  const api = 'policies/policy_qr';
  const url = `${endpoints.policy}/${api}/refcode-passport?refCode=${refCode}&passport=${passport}`;
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const response = await handleRequest(url, options);
  const parsedResponse = await handleResponse(response);
  return parsedResponse.data;
}

export { getPolicyByQrCode, getPolicyByRefCodeAndPassport };