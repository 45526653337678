import * as actionTypes from './actionType';
import * as services from '../@services';

const getPolicyByQrCode = (token = '') => async (dispatch) => {
  dispatch({type: actionTypes.LOADING});
  try {
    const response = await services.getPolicyByQrCode(token);
    if(response.status === 401) {
      dispatch({type: actionTypes.UNAUTHORIZED});
    }else if (response.status === 404) {
      dispatch({type: actionTypes.NO_FOUND, payload: response.data});
    }else if (response.status === 200) {
      dispatch({type: actionTypes.GET_POLICY, payload: response.data});
    }else {
      dispatch({type: actionTypes.FAILED});
    }
  } catch (error) {
    dispatch({type: actionTypes.FAILED});
  }
};
const getPolicyByRefCodeAndPassport = (policyInfo) => {
  return services.getPolicyByRefCodeAndPassport(policyInfo);
}

export {
  getPolicyByQrCode,
  getPolicyByRefCodeAndPassport,
};