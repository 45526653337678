const product = { 
  color: '#4674c9',
  dateFormat: 'DD/MM/YYYY',
  favicon: require(`../@theme/images/abcInsurance/favicon.ico`), /**require path cannot be dynamic */
  logo:  require(`../@theme/images/abcInsurance/logo.png`),  /**require path cannot be dynamic */
  title: 'Motor Insurance',
  currencySymbol: 'US$',
  currencyFormat: '0,0.00',
  sections: [
    {title: 'POLICY DETAILS', startIndexInclusive: 0, endIndexInclusive: 5},
    {title: ' ', startIndexInclusive: 6, endIndexInclusive: 9},
    {title: ' ', startIndexInclusive: 10, endIndexInclusive: 11},
    {title: 'INSURED DETAILS', startIndexInclusive: 12, endIndexInclusive: 14},
    {title: 'ADDITIONAL DRIVER DETAILS', startIndexInclusive: 15, endIndexInclusive: 15,customStyle:{
      marginTop:20
      }},
  ]
};

export default product;
