import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {  Header, Body } from '../@containers';
import { Loader, Feedback } from '../@components';
import { removeHashSlash, getProductConfig } from '../@lib';
import { getPolicyByQrCode } from '../@redux/action';

const Loaded = ({policy}) => {
  let productCode = policy.item ? policy.item.list[0].productCode : '';
  return(
    <>
      <Header productCode={productCode}/>
      { policy.unauthorized ? <Feedback message='Access is denied.'/>
        : policy.isFailed ? <Feedback  message='Oops! Something went wrong. Please try again later.' />
        : policy.notFound ?  <Feedback  message='Policy not found.' />
        : <Body policy={policy.item}/>
      }
    </>
  );
};

const Head = ({productCode}) => {
  const product = getProductConfig(productCode);
  return(
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`${product.title} | Policy Summary`}</title>
      <link rel="icon" href={product.favicon} />
      <meta name="theme-color" content={product.color} />
    </Helmet>
  );
};

class Main extends React.PureComponent {

  componentDidMount() {
    const { getPolicyByQrCode } = this.props;
    const token = removeHashSlash(window.location.hash);
    getPolicyByQrCode(token);
  }

  render() {
    const { policy } = this.props;
    const productCode = policy.item ? policy.item.list[0].productCode : '';
    return(
      <div className='xc-main'>
        { policy.isLoading ? <Loader />
        : (
         <>
          <Head productCode={productCode}/>
          <Loaded policy={policy} />
        </>
        )}
      </div>
      );
  }
}
const mapStateToProps = (state) => {
  return {
    policy : state.policy,
  }
};

const mapDispatchToProps = {
  getPolicyByQrCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
