const product = {
    color: '#4674c9',
    dateFormat: 'DD/MM/YYYY',
    favicon: require(`../@theme/images/abcInsurance/favicon.ico`), /**require path cannot be dynamic */
    logo:  require(`../@theme/images/abcInsurance/logo.png`),  /**require path cannot be dynamic */
    title: 'MyMotor Insurance',
    currencySymbol: 'RM',
    currencyFormat: '0,0.00',
    sections: [
        {
            title: 'POLICY DETAILS', startIndexInclusive: 0, endIndexInclusive: 3,config:{
                customRow:[
                    {
                        id:'properties.Sum Assured',
                        type:'currency'
                    }
                ]
            }
        },
        {title: ' ', startIndexInclusive: 4, endIndexInclusive: 8 , config:{
                customRow:[
                    {
                        id:'properties.NCD',
                        type:'percent'
                    }
                ]
            }},
        {title: ' ', startIndexInclusive: 9, endIndexInclusive: 10},
        {title: 'CAR DETAILS', startIndexInclusive: 11, endIndexInclusive: 13},
        {title: "CAR OWNER’S DETAILS", startIndexInclusive: 14, endIndexInclusive: 16,customStyle:{
                marginTop:20
            }},
    ]
};

export default product;
