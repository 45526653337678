import React from 'react';
import { getProductConfig } from '../@lib';

const Header = ({productCode}) => {
  const product = getProductConfig(productCode);
  return(
    <div className='xc-header space-btn'>
      {product.logo ? <img src={product.logo} alt={product.title} height='60px' width='' /> : <></>}
    </div>
  );
}

export default Header;
