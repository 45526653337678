import React from 'react';
import { ClipLoader } from 'react-spinners';
import { XColor } from '../@theme/index.scss';

const Loader = () => {
  return(
    <div id='loader'>
      <ClipLoader 
      size={100}
      color={XColor}
      />
    </div>   
  )
}

export default Loader;