const product = {
    color: '#4674c9',
    favicon: require(`../@theme/images/abcInsurance/favicon.ico`), /**require path cannot be dynamic */
    logo:  require(`../@theme/images/abcInsurance/logo.png`),  /**require path cannot be dynamic */
    title: 'Auto Insurance',
    currencySymbol: 'US$',
    currencyFormat: '0,0.00',
    dateFormat: 'DD/MMM/YYYY',
    sections: [
        {title: 'POLICY DETAILS', startIndexInclusive: 0, endIndexInclusive: 3},
        {title: '', startIndexInclusive: 4, endIndexInclusive: 6},
        {title: '', startIndexInclusive: 7, endIndexInclusive: 8},
        {title: 'INSURED DETAILS', startIndexInclusive: 10, endIndexInclusive:11,config:{}},
        {title: 'PROPERTY DETAILS', startIndexInclusive: 15, endIndexInclusive: 17},
        {title: "BENEFICIARY DETAILS", startIndexInclusive: 18, endIndexInclusive: 19,customStyle:{marginTop:20}},
    ],
    withFilteredSections(properties){
        const {['Main Insured']:mainInsured} = properties;

        if (mainInsured['Belongs to Company'] === 'No') {
            return {
                ...this,
                sections: this.sections.map((el)=>{
                    if(el.title === 'INSURED DETAILS'){
                        return {
                            ...el,
                            startIndexInclusive: 12,
                            endIndexInclusive: 14
                        }
                    }

                    if(el.title === 'BENEFICIARY DETAILS'){
                        return {
                            ...el,
                            startIndexInclusive: 20,
                            endIndexInclusive: 22
                        }
                    }
                    return  el;
                })
            };
        }
        return this;
    }
};

export default product;
