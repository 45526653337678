const product = {
    color: '#4674c9',
    dateFormat: 'DD/MM/YYYY',
    favicon: require(`../@theme/images/abcInsurance/favicon.ico`), /**require path cannot be dynamic */
    logo:  require(`../@theme/images/abcInsurance/logo.png`),  /**require path cannot be dynamic */
    title: 'Home Insurance',
    currencySymbol: 'USD',
    currencyFormat: '0,0.00',
    sections: [
        {title: 'POLICY DETAILS', startIndexInclusive: 0, endIndexInclusive: 6},
        {title: ' ', startIndexInclusive: 7, endIndexInclusive: 10},
        {title: 'INSURED DETAILS', startIndexInclusive: 11, endIndexInclusive: 13},
        {title: "INSURED PROPERTY", startIndexInclusive: 14, endIndexInclusive: 19,customStyle:{
                marginTop:20
            }},
    ]
};

export default product;
