const product = { 
  color: '#c1241b', 
  currencySymbol: 'PKR',
  currencyFormat: '0,0',
  dateFormat: 'DD/MM/YYYY',
  favicon: require(`../@theme/images/alfalah/favicon.ico`), /**require path cannot be dynamic */
  logo:  require(`../@theme/images/alfalah/logo.png`),  /**require path cannot be dynamic */
  title: 'Alfalah Insurance', 
  sections: [
    {title: 'POLICY DETAILS', startIndexInclusive: 0, endIndexInclusive: 12},
    {title: 'INSURED DETAILS', startIndexInclusive: 13, endIndexInclusive: 15},
    {title: 'BENEFICIARY DETAILS', startIndexInclusive: 16, endIndexInclusive: 16}, 
  ],
  calculations: [
    {
      id: 'properties.End Date', 
      calculate: (value, format, moment) => moment(value, format).add('years', 1).format(format)
    },
  ]
};

export default product;
