import { combineReducers } from 'redux';
import * as actionTypes from './actionType';

const initial = {
  isLoading: true,
  isFailed: false,
  notFound: false,
  unauthorized: false,
  item: null,
}
const policyReducer = function(state = initial, action) {
  switch(action.type) {
    case actionTypes.LOADING: 
      return {
        ...initial,
      };
    case actionTypes.FAILED: 
      return {
        isLoading: false,
        isFailed: true,
        notFound: false,
        unauthorized: false,
        item: null,
      };
    case actionTypes.UNAUTHORIZED:
      return {
        isLoading: false,
        isFailed: false,
        notFound: false,
        unauthorized: true,
        item: null,
      }
    case actionTypes.NO_FOUND:
      return {
        isLoading: false,
        isFailed: false,
        notFound: true,
        unauthorized: false,
        item: null,
      }
    case actionTypes.GET_POLICY:
      return {
        isLoading: false,
        isFailed: false,
        notFound: false,
        unauthorized: false,
        item: action.payload,
      }
    default:
      return state;
  }
};

const combinedReducer = combineReducers({
  policy: policyReducer,
})
export default combinedReducer;