import React from 'react';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '../@redux/store';
import Navigation from "./navigation";


import '../@theme/index.scss';

function App() {
  return (
    <>
    <Provider store={store}>
      <HashRouter>
        <Navigation />
      </HashRouter> 
    </Provider>
  </>
  );
}

export default App;
